// @ts-ignore
/* eslint-disable */
import request from '@/request';

/** getChatHistory GET /api/chatMessage/history */
export async function getChatHistoryUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getChatHistoryUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseListChatMessage_>('/api/chatMessage/history', {
    method: 'GET',
    params: {
      // limit has a default value: 20
      limit: '20',
      ...params,
    },
    ...(options || {}),
  });
}

/** sendChatMessage POST /api/chatMessage/send */
export async function sendChatMessageUsingPost(
  body: API.ChatMessage,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseString_>('/api/chatMessage/send', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
