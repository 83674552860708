<template>
  <section class="m-calendar" :style="dateStyle">
    <!-- 切换月份 -->
    <header class="changeMonth">
      <span class="prepMonth" @click="changeMonth(-1)"></span>
      <h1>{{ year }}年{{ month }}月</h1>
      <span class="nextMonth" @click="changeMonth(1)"></span>
    </header>
    <ul class="dates">
      <!-- 星期 -->
      <li class="weeks" v-for="item in weeks" :key="item">{{ item }}</li>
      <!-- 日期 -->
      <li
        class="day"
        v-for="(item, i) in dates"
        :key="i"
        :class="{
          isPrep: item.isPrep,
          isNext: item.isNext,
          hidden: (item.isNext || item.isPrep) && !showPrepNext,
          isToday: item.date === today,
          isSelected: item.date === selectedDate,
          isMarked: markDates.includes(item.date),
        }"
        @click="clickDate(item)"
      >
        {{ item.date === today ? "今天" : item.day }}
      </li>
    </ul>
  </section>
</template>

<script>
const D = new Date();
const ThisYear = D.getFullYear();
const ThisMonth = D.getMonth() + 1;
const today = new Date()
  .toLocaleDateString("zh-CN", { timeZone: "Asia/Shanghai" })
  .split("T")[0]; // 保持为 yyyy-MM-dd 格式
// const today = new Date().toLocaleDateString("zh-CN", { timeZone: 'Asia/Shanghai' }).split("/").map(n => n.padStart(2, '0')).join("-"); // 保持为 yyyy-MM-dd 格式
export default {
  props: {
    // 初始年月
    startYearMonth: {
      type: String,
      default() {
        return `${ThisYear}-${ThisMonth}`;
        // 格式：2021-1 或 2020-01
      },
    },
    // 需要标记的日期数组
    markDate: {
      type: Array,
      default() {
        return [];
        // 格式：['2020-01-01', '2020-02-12']
      },
    },
    // 选中的日期
    checkedDate: {
      type: String,
      default() {
        return "";
        // 格式：'2020-01-01'
      },
    },
    // 是否星期一开始，默认星期日开始
    mondayStart: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // 是否显示上个月和下个月日期
    showPrepNext: {
      type: Boolean,
      default() {
        return true;
      },
    },
    // 日期字体颜色
    fontColor: {
      type: String,
      default() {
        return "#000";
      },
    },
    // 标记点颜色
    markColor: {
      type: String,
      default() {
        return "#ff6633";
      },
    },
    // 选中的日期字体颜色
    activeColor: {
      type: String,
      default() {
        return "#fff";
      },
    },
    // 选中的日期背景颜色
    activeBgColor: {
      type: String,
      default() {
        return "#ff6633";
      },
    },
  },
  data() {
    return {
      // 当前年
      year: ThisYear,
      // 当前月
      month: ThisMonth,
      // 今天
      today,
      // 日期数组
      dates: [],
      // 选中的日期
      selectedDate: "",
    };
  },
  computed: {
    // 标记的日期
    markDates() {
      return this.markDate;
    },
    // 星期
    weeks() {
      if (this.mondayStart) {
        return ["一", "二", "三", "四", "五", "六", "日"];
      } else {
        return ["日", "一", "二", "三", "四", "五", "六"];
      }
    },
    dateStyle() {
      return {
        "--font-color": this.fontColor,
        "--mark-color": this.markColor,
        "--active-color": this.activeColor,
        "--active-bg-color": this.activeBgColor,
      };
    },
  },
  created() {
    this.year = new Date(this.startYearMonth).getFullYear();
    this.month = new Date(this.startYearMonth).getMonth() + 1;
    // 选中的日期
    if (this.checkedDate) {
      this.selectedDate = this.checkedDate;
    }
    // 初始化日历
    this.initCustomCalendar();
  },
  methods: {
    // 初始化日历
    initCustomCalendar() {
      const prepMonthDays = new Date(this.year, this.month - 1, 0).getDate(); // 上个月的天数
      const prepMonthEndDayWeek = new Date(
        this.year,
        this.month - 1,
        0
      ).getDay(); // 上个月最后一天的星期
      const thisMonthDays = new Date(this.year, this.month, 0).getDate(); // 当前月的天数
      const firstDayWeek = new Date(this.year, this.month - 1, 1).getDay(); // 当前月1号的星期
      const thisEndDayWeek = new Date(this.year, this.month, 0).getDay(); // 当前月最后一天的星期

      let dates = [];
      let totalDays = firstDayWeek + thisMonthDays;
      let index = this.mondayStart ? 1 : 0; // 如果星期一开始，index为1，否则为0

      // 处理日期溢出的逻辑
      if (this.mondayStart && thisEndDayWeek > 0) {
        totalDays += 7 - thisEndDayWeek;
      } else if (!this.mondayStart && thisEndDayWeek < 6) {
        totalDays += 6 - thisEndDayWeek;
      }

      // 构建日历日期
      for (index; index < totalDays; index++) {
        if (index < firstDayWeek) {
          const day = prepMonthDays - prepMonthEndDayWeek + index; // 上个月日期
          const date = new Date(this.year, this.month - 2, day)
            .toLocaleDateString("zh-CN", { timeZone: "Asia/Shanghai" })
            .split("/")
            .map((n) => n.padStart(2, "0"))
            .join("-"); // 保持 yyyy-MM-dd 格式
          dates.push({ isPrep: true, day, date });
        } else if (index >= firstDayWeek + thisMonthDays) {
          const day = index - thisMonthDays - firstDayWeek + 1; // 下个月日期
          const date = new Date(this.year, this.month, day)
            .toLocaleDateString("zh-CN", { timeZone: "Asia/Shanghai" })
            .split("/")
            .map((n) => n.padStart(2, "0"))
            .join("-"); // 保持 yyyy-MM-dd 格式
          dates.push({ isNext: true, day, date });
        } else {
          const day = index - firstDayWeek + 1; // 当前月日期
          const date = new Date(this.year, this.month - 1, day)
            .toLocaleDateString("zh-CN", { timeZone: "Asia/Shanghai" })
            .split("/")
            .map((n) => n.padStart(2, "0"))
            .join("-"); // 保持 yyyy-MM-dd 格式
          dates.push({ day, date });
        }
      }
      this.dates = [...dates];
    },
    // 点击日期
    clickDate({ date, isPrep, isNext }) {
      if (isPrep || isNext) return; // 如果点击的是上个月或下个月的日期，直接返回
      this.selectedDate = date;
      this.$emit("clickDate", date);
    },
    // 切换月份
    changeMonth(month) {
      this.month += month;
      if (this.month === 0) {
        this.month = 12;
        this.year--;
      } else if (this.month === 13) {
        this.month = 1;
        this.year++;
      }
      this.initCustomCalendar();
      this.$emit(
        "changeMonth",
        `${this.year}-${this.month.toString().padStart(2, "0")}` // 保持两位数月份格式
      );
    },
  },
};
</script>

<style lang="scss" scoped>
$fontColor: var(--font-color);
$markColor: var(--mark-color);
$activeColor: var(--active-color);
$activeBgColor: var(--active-bg-color);

.m-calendar {
  max-width: 400px;
  max-height: 450px;
  padding-bottom: 20px;
  border: 1px solid #054c96;
  border-radius: 8px 8px 0 0;

  header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    padding: 20px 0;
    border-bottom: 1px solid #054c96;

    h1 {
      width: 120px;
      margin: 0 20px;
      color: #444;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
    }

    span {
      padding: 4px 10px;
      cursor: pointer;

      &::after {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-top: 2px solid $fontColor;
        content: "";
      }

      &.prepMonth::after {
        border-left: 2px solid $fontColor;
        transform: rotate(-45deg);
      }

      &.nextMonth::after {
        border-right: 2px solid $fontColor;
        transform: rotate(45deg);
      }

      &:hover::after {
        border-color: $markColor;
      }
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0 12px;

    li {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      margin: 4px calc((100% / 7 - 42px) / 2);
      font-weight: bold;
      border-radius: 6px;
      transition: all ease 0.25s;
      // 标记
      &::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        transform: translateX(-50%);
        content: "";
      }

      // 星期
      &.weeks {
        margin-bottom: 12px;
        color: #444;
        font-size: 18px;
      }

      &.day {
        color: $fontColor;
        font-size: 20px;
        cursor: pointer;
        // 今天
        &.isToday {
          color: $markColor;
        }

        // 标记
        &.isMarked::after {
          background: $markColor;
          transition: all ease 0.25s;
        }

        // 选中、hover
        &:hover,
        &.isSelected {
          color: $activeColor;
          background: $activeBgColor;

          &:after {
            display: none;
          }
        }

        // 上个月、下个月
        &.isNext,
        &.isPrep {
          cursor: default;
          opacity: 0.3;

          &:hover {
            color: $fontColor;
            background: transparent;
            opacity: 0.3;
          }
        }

        // hidden
        &.hidden {
          opacity: 0;

          &:hover {
            opacity: 0;
          }
        }
      }
    }
  }
}
</style>
