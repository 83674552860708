<template>
  <div id="app">
    <template v-if="route.path.startsWith('/user')">
      <router-view />
    </template>
    <template v-else>
      <BasicLayout />
      <SpeedInsights :url="fullUrl" />
    </template>
    <ChatWidget />
    <!-- 使用 ChatWidget 组件 -->
  </div>
</template>

<script setup lang="ts">
import BasicLayout from "@/layouts/BasicLayout.vue";
import { SpeedInsights } from "@vercel/speed-insights/vue";
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import ChatWidget from "@/components/ChatWidget.vue"; // 导入 ChatWidget 组件

const route = useRoute();
const fullUrl = ref(""); // 使用 ref 以便于响应式更新

const doInit = () => {
  console.log("hello 欢迎来到我的项目");
  // 动态生成完整的页面 URL
  fullUrl.value = `${window.location.origin}${route.fullPath}`;
};

onMounted(() => {
  doInit();
});
</script>

<style scoped>
#app {
  margin-top: 0; /* 修改为 0 或删除此行 */
  color: #2c3e50;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.basic-layout {
  min-height: 100vh;
  padding: 20px;
  background-color: #f9f9f9;
}
</style>
