<template>
  <div id="basicLayout">
    <a-layout style="height: 100vh">
      <a-alert banner center v-if="showLoading">
        注意第一次加载慢后台正在启动中
      </a-alert>
      <a-layout-header class="header">
        <GlobalHeader />
      </a-layout-header>
      <a-layout-content class="content">
        <router-view />
        <a-watermark content="徐煜程">
          <div style="width: 100%; height: 50px" />
        </a-watermark>
      </a-layout-content>
      <a-layout-footer class="footer">
        <div class="footer-content">
          <div class="footer-links">
            <h4>链接</h4>
            <a href="https://www.github.com/Xuyuyu520" target="_blank">
              <span><icon-github /> GitHub</span>
            </a>
            <a href="https://home-alpha-ten.vercel.app/" target="_blank">
              <icon-home />
              我的开发主页
            </a>
            <a href="mailto:your-email@example.com">
              <icon-mail />
              联系我们
            </a>
            <a href="/privacy-policy">
              <icon-shield />
              隐私政策
            </a>
            <a href="/terms-of-service">
              <icon-info-circle />
              使用条款
            </a>
          </div>

          <div class="footer-services">
            <h4>服务</h4>
            <div class="service-links">
              <a href="/faq">常见问题</a>
              <a href="/support">技术支持</a>
              <a href="/feedback">反馈</a>
              <a href="/careers">加入我们</a>
            </div>
          </div>

          <div class="footer-tags">
            <h4>标签</h4>
            <div class="tag-links">
              <a
                href="https://docs.oracle.com/javase/8/docs/technotes/guides/jweb/index.html"
                target="_blank"
                >Java</a
              >
              <a href="https://www.python.org/doc/" target="_blank">Python</a>
              <a href="https://www.ai-drawing.com/" target="_blank">AI 绘画</a>
              <a
                href="https://www.coursera.org/browse/data-science"
                target="_blank"
                >数据科学</a
              >
              <a
                href="https://www.coursera.org/browse/data-science/machine-learning"
                target="_blank"
                >机器学习</a
              >
              <a
                href="https://www.coursera.org/browse/data-science/natural-language-processing"
                target="_blank"
                >自然语言处理</a
              >
            </div>
          </div>

          <div class="footer-ai-resources">
            <h4>AI 资源</h4>
            <div class="resource-links">
              <a href="https://www.ai-tools.com/" target="_blank">AI 工具</a>
              <a href="https://github.com/openai" target="_blank">AI 库</a>
              <a
                href="https://www.coursera.org/courses?query=ai"
                target="_blank"
                >AI 课程</a
              >
              <a href="https://www.ai-community.com/" target="_blank"
                >AI 社区</a
              >
            </div>
          </div>

          <div class="footer-social-media">
            <h4>关注我们</h4>
            <div class="social-links">
              <a
                href="https://www.linkedin.com/in/your-profile"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  width="16"
                  height="16"
                >
                  <path
                    d="M1.147 0A1.146 1.146 0 0 0 0 1.146v13.708C0 15.75.75 16 1.146 16h13.708A1.146 1.146 0 0 0 16 14.854V1.146A1.146 1.146 0 0 0 14.854 0H1.146zm4.61 14.314H3.076V6.47h2.681v7.844zm-1.343-9.032a1.568 1.568 0 1 1 0-3.136 1.568 1.568 0 0 1 0 3.136zM14.25 14.314h-2.682V9.711c0-1.051-.022-2.404-1.464-2.404-1.46 0-1.684 1.142-1.684 2.324v4.683H7.375V6.47h2.572v1.063h.036c.359-.679 1.247-1.394 2.563-1.394 2.747 0 3.248 1.824 3.248 4.191v4.982z"
                  />
                </svg>
              </a>
              <a href="https://twitter.com/your-profile" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  width="16"
                  height="16"
                >
                  <path
                    d="M5.026 15.193c6.031 0 9.32-5.007 9.32-9.321 0-.142 0-.284-.007-.425A6.671 6.671 0 0 0 16 3.38a6.547 6.547 0 0 1-1.889.517A3.305 3.305 0 0 0 15.557 2a6.569 6.569 0 0 1-2.085.797A3.282 3.282 0 0 0 7.875 5.118c0 .254.029.504.086.743A9.322 9.322 0 0 1 1.114 2.55 3.283 3.283 0 0 0 2.974 8.127 3.191 3.191 0 0 1 1.486 7.64c.019.271.019.54.019.81a3.281 3.281 0 0 0 2.627 3.215A3.274 3.274 0 0 1 2.4 12.555a3.27 3.27 0 0 0 3.053 2.26A6.572 6.572 0 0 1 0 14.128a9.305 9.305 0 0 0 5.026 1.065z"
                  />
                </svg>
              </a>
              <a href="https://www.facebook.com/your-profile" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  width="16"
                  height="16"
                >
                  <path
                    d="M8.293 8.293a1 1 0 0 1 0 1.414L6.414 12H7.5a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h2.086l1.293-1.293a1 1 0 0 1 1.414 1.414L7.5 7h2.293l-1.293 1.293zM16 8a8 8 0 1 1-8-8 8 8 0 0 1 8 8z"
                  />
                </svg>
              </a>
              <a href="https://www.instagram.com/your-profile" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  width="16"
                  height="16"
                >
                  <path
                    d="M8 0C3.582 0 0 3.582 0 8c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8zm2.67 10.01h-2.048a3.94 3.94 0 0 0 1.417-2.977 3.943 3.943 0 0 0-3.943-3.942c-.563 0-1.1.085-1.615.247-.063.021-.124.051-.187.074a1.003 1.003 0 0 1-.31.042c-.152 0-.303-.028-.447-.086a.751.751 0 0 1-.25-.086c-.151-.056-.295-.134-.436-.224-.075-.052-.146-.096-.219-.147-.055-.038-.115-.065-.178-.085-.063-.021-.126-.045-.192-.067a5.185 5.185 0 0 0-.814-.154c-.162 0-.314.016-.467.052-.016.006-.034.014-.052.017-.062.008-.126.013-.187.017-.11.006-.22.013-.328.036.002.03.003.062.003.092a4.002 4.002 0 0 0 3.774 3.764 4.006 4.006 0 0 0 4.014-4.014A3.97 3.97 0 0 0 10.67 4a3.963 3.963 0 0 0-.438-.021 4.002 4.002 0 0 0-1.83.44c-.514.354-1.006.72-1.467 1.105.45.007.908.05 1.367.147a3.92 3.92 0 0 1 3.055 3.73c0 .186.018.37.05.547z"
                  />
                </svg>
              </a>
            </div>
          </div>

          <div class="footer-my-section">
            <h4>我的</h4>
            <a href="/my-profile">我的个人资料</a>
            <a href="/my-blog">我的博客</a>
            <a href="/my-projects">我的项目</a>
          </div>
        </div>
      </a-layout-footer>
    </a-layout>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import GlobalHeader from "../components/GlobalHeader.vue";

export default defineComponent({
  name: "BasicLayout",
  components: {
    GlobalHeader,
  },
  data() {
    return {
      showLoading: true,
    };
  },
  mounted() {
    // Simulate loading
    setTimeout(() => {
      this.showLoading = false;
    }, 1000);
  },
});
</script>
<style scoped>
#basicLayout {
}

.header {
  margin-bottom: 18px;
  box-shadow: blanchedalmond 5px 5px 10px;
}

.content {
  margin-bottom: 20px;
  padding: 20px;
  background: linear-gradient(to right, #fefefe, #fff);
}

.footer {
  padding: 24px;
  text-align: center;
  background: #f0f2f5;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* 确保内容均匀分布 */
}

.footer-links,
.footer-services,
.footer-tags,
.footer-ai-resources,
.footer-social-media,
.footer-contact,
.footer-my-section {
  /* 新增的我的栏目 */
  flex: 1 1 200px; /* 允许元素增长和收缩 */
  margin: 10px; /* 适当的边距 */
}

.footer-links {
  display: flex;
  flex-direction: column; /* 垂直排列 */
  gap: 10px; /* 设置间距 */
}

.service-links {
  display: flex;
  flex-direction: column; /* 垂直排列 */
  gap: 10px; /* 设置间距 */
}

.tag-links,
.resource-links,
.social-links {
  display: flex;
  flex-wrap: wrap; /* 使链接在空间不足时换行 */
  gap: 8px;
}

.footer-services h4,
.footer-tags h4,
.footer-ai-resources h4,
.footer-social-media h4,
.footer-contact h4,
.footer-my-section h4 {
  /* 适用于我的栏目 */
  margin-bottom: 10px;
  font-weight: bold;
}

.footer-my-section a {
  display: block; /* 每个链接独占一行 */
  margin-bottom: 5px;
  color: #1890ff; /* 链接颜色 */
  text-decoration: none; /* 去掉下划线 */
}

.footer-my-section a:hover {
  text-decoration: underline; /* 悬停时显示下划线 */
}

.footer-bottom {
  margin-top: 20px; /* 添加顶部边距以与上面的内容分开 */
  color: #888; /* 字体颜色 */
  font-size: 12px; /* 字体大小 */
}
</style>
