import { createApp } from "vue";
import ArcoVue from "@arco-design/web-vue";
import ArcoVueIcon from "@arco-design/web-vue/es/icon";
import "@arco-design/web-vue/dist/arco.css";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "../src/access";

const pinia = createPinia();

const app = createApp(App);
app.use(ArcoVue);
app.use(ArcoVueIcon);
app.use(router);
app.use(pinia);
app.mount("#app");

const debounce = ({
  fn,
  delay,
}: {
  fn: (...args: any[]) => void;
  delay: number;
}) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback: any) {
    callback = debounce({ fn: callback, delay: 200 });

    super(callback);
  }
};
