<template>
  <div id="userInfoPage">
    <a-card>
      <a-row style="margin-bottom: 16px">
        <a-col flex="auto" class="content-wrapper">
          <h2>用户名: {{ data?.userName }}</h2>
          <p>用户简介: {{ data?.userProfile }}</p>
          <p>
            <a-space>
              个人信息：
              <div :style="{ display: 'flex', alignItems: 'center' }">
                <a-avatar
                  :size="24"
                  :image-url="data?.userAvatar"
                  :style="{ marginRight: '8px' }"
                />
                <a-typography-text
                  >{{ data?.userName ?? "无名" }}
                </a-typography-text>
              </div>
            </a-space>
          </p>
          <p>
            创建时间：{{
              dayjs(data?.createTime).format("YYYY-MM-DD HH:mm:ss")
            }}
          </p>
          <h2>UID: {{ data?.id }}</h2>
          <a-space size="medium">
            <a-button @click="goToEditPage"> 修改信息</a-button>
          </a-space>
        </a-col>
        <a-col flex="320px">
          <a-image width="100%" :src="data?.userAvatar" />
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import { useLoginUserStore } from "@/store/userStore";
import { getUserVoByIdUsingGet } from "@/api/userController";

const router = useRouter();

const data = ref<API.UserVO>();

// 获取登录用户
const loginUserStore = useLoginUserStore();
let loginUserId = loginUserStore.loginUser?.id;

/**
 * 加载数据
 */
const loadData = async () => {
  if (!loginUserId) {
    return;
  }
  const res = await getUserVoByIdUsingGet({
    id: loginUserStore.loginUser.id as any,
  });
  console.log(res.data);
  if (res.data.code === 0) {
    data.value = res.data.data as any;
  } else {
    message.error("获取数据失败，" + res.data.message);
  }
};

const goToEditPage = async () => {
  await loadData(); // 获取用户信息
  await router.push({
    path: "/add/user",
    query: { id: loginUserStore.loginUser?.id },
  });
};

// 监听分页变化
watchEffect(() => {
  loadData();
});
</script>

<style scoped>
#appDetailPage {
}

#appDetailPage .content-wrapper > * {
  margin-bottom: 24px;
}
</style>
