<template>
  <a-row class="globalHeader" align="center" :wrap="false">
    <a-col flex="auto">
      <a-menu
        mode="horizontal"
        theme="dark"
        :selected-keys="selectedKeys"
        @menu-item-click="doMenuClick"
      >
        <a-menu-item
          key="0"
          :style="{ padding: 0, marginRight: '38px' }"
          disabled
        >
          <div class="titleBar">
            <img src="../assets/logo.png" alt="" class="logo" />
            <div class="title">徐答答</div>
          </div>
        </a-menu-item>
        <a-menu-item v-for="items in visibleRoutes" :key="items.path"
          >{{ items.name }}
        </a-menu-item>
      </a-menu>
    </a-col>
    <a-col flex="50px" :style="{ textAlign: 'right', width: '80px' }">
      <div
        v-if="loginUserStore.loginUser.id"
        :style="{ padding: 0, marginRight: '38px' }"
      >
        <div>
          <a-dropdown @select="handleSelect">
            <a-avatar
              :size="64"
              style="justify-content: center"
              :imageUrl="loginUserStore.loginUser.userAvatar"
              @click="handleSelect"
            />
            <template #content>
              <a-doption @click="userInfo">个人中心</a-doption>
              <a-doption @click="signIn">签到</a-doption>
              <a-doption type="text" style="color: #f522f5" @click="logout"
                >退出登录
              </a-doption>
            </template>
          </a-dropdown>
        </div>
      </div>
      <div v-else style="background: #165dff; align-items: center">
        <a-button
          style="height: 58px; width: auto"
          type="primary"
          href="/user/login"
          >登录
        </a-button>
      </div>
    </a-col>
  </a-row>
</template>

<script setup lang="ts">
import { routes } from "@/router/routers";
import { useRouter } from "vue-router";
import { compile, computed, ref } from "vue";
import { useLoginUserStore } from "@/store/userStore";
import checkAccess from "@/access/checkAccess";
import { userLoginUsingPost, userLogoutUsingPost } from "@/api/userController";
import message from "@arco-design/web-vue/es/message";

const loginUserStore = useLoginUserStore();
const router = useRouter();
// 默认选中
const selectedKeys = ref(["/"]);
//路由跳转时。自动更新菜单项
router.afterEach((to, from, failure) => {
  selectedKeys.value = [to.path];
});
//展示在菜单栏的菜单项
const visibleRoutes = computed(() => {
  return routes.filter((item) => {
    if (item.meta?.hideInMenu) {
      return false;
    }
    if (!checkAccess(loginUserStore.loginUser, item.meta?.access as string)) {
      return false;
    }
    return true;
  });
});
//点击菜单跳转页面
const doMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};
const handleSelect = (v: any) => {
  console.log(v);
};
/**
 * 提交登录
 */
const logout = async () => {
  const res = await userLogoutUsingPost();
  console.log(res.data);
  if (res.data.code === 0) {
    message.success("登出成功");
    router.push({
      path: "/user/login",
      replace: true,
    });
  } else {
    message.error(res.data.message as string);
  }
};
/**
 * 查看个人信息
 */
const userInfo = async () => {
  router.push({
    path: "/info/user",
    replace: true,
  });
};
//签到功能
const isSigned = ref<boolean>(false); // 将isSigned的类型改为布尔类型
const signIn = async () => {
  isSigned.value = true; // 更新签到状态为true
  router.push({
    path: "/sign/in",
    replace: true,
    query: {
      isSigned: "true", // 更新路由参数为true
    },
  });
};
</script>

<style scoped>
#globalHeader {
}

.titleBar {
  display: flex;
  align-items: center;
}

.title {
  margin-left: 16px;
  color: bisque;
}

.logo {
  width: 30px;
  height: 30px;
}
</style>
