/**
 * 权限定义
 */
const ACCESS_ENUM = {
  // 用户权限
  USER: "user",
  // 管理员权限
  ADMIN: "admin",
  //未登录
  NOT_LOGIN: "no_login",
};
export default ACCESS_ENUM;
