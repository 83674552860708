// @ts-ignore
/* eslint-disable */
import request from '@/request';

/** chatCompletions GET /api/chatglm/completions */
export async function chatCompletionsUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.chatCompletionsUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<string>('/api/chatglm/completions', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** getChatGlmRecord GET /api/chatglm/record */
export async function getChatGlmRecordUsingGet(options?: { [key: string]: any }) {
  return request<API.BaseResponseListChatGLM_>('/api/chatglm/record', {
    method: 'GET',
    ...(options || {}),
  });
}

/** updateChatGlmRecord PUT /api/chatglm/record */
export async function updateChatGlmRecordUsingPut(
  body: API.ChatGLM,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/chatglm/record', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** createChatGlmRecord POST /api/chatglm/record */
export async function createChatGlmRecordUsingPost(
  body: API.ChatRequestDto,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseChatGLM_>('/api/chatglm/record', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
