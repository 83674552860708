<template>
  <div id="chatRoomPage">
    <div class="chat-room-container">
      <div class="user-list">
        <h3>聊天用户</h3>
        <ul>
          <li
            v-for="user in userList"
            :key="user.id"
            @click="selectUser(user)"
            :class="{ active: selectedUser?.id === user.id }"
          >
            <a-avatar :size="40" :image-url="user.userAvatar" />
            <span class="username">{{ user.userAccount }}</span>
          </li>
        </ul>
      </div>

      <div class="chat-interface" v-if="selectedUser">
        <div class="chat-header">
          <a-avatar :size="50" :image-url="selectedUser.userAvatar" />
          <span class="chat-username">{{ selectedUser.userAccount }}</span>
        </div>

        <div class="message-list" ref="messageList">
          <ul>
            <li v-for="msg in filteredMessages" :key="msg.id">
              <div
                :class="{
                  'message-container': true,
                  'message-right': msg.senderId === currentUser?.id,
                  'message-left': msg.senderId !== currentUser?.id,
                }"
              >
                <a-avatar :size="40" :image-url="getUserAvatar(msg.senderId)" />
                <div class="message-content">
                  <p class="username">{{ getUserName(msg.senderId) }}</p>
                  <p class="message-time">
                    {{ dayjs(msg.createTime).format("YYYY-MM-DD HH:mm") }}
                  </p>
                  <p class="message-bubble">{{ msg.content }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="message-input">
          <a-input
            v-model="privateMessage"
            placeholder="聊点什么吧..."
            style="width: calc(100% - 60px)"
          />
          <a-button type="primary" icon="icon-send" @click="sendPrivateMessage">
            发送
          </a-button>
        </div>
      </div>

      <div class="chat-placeholder" v-else>
        <p>请选择一个用户进行聊天</p>
      </div>
    </div>
    <ChatSphere />
  </div>
</template>

<script setup lang="ts">
import API from "@/api";
import { computed, nextTick, onMounted, onUnmounted, ref } from "vue";
import dayjs from "dayjs";
import {
  getUserVoByIdUsingGet,
  listUserByPageUsingPost,
} from "@/api/userController";
import { useLoginUserStore } from "@/store/userStore";
import message from "@arco-design/web-vue/es/message";
import {
  getChatHistoryUsingGet,
  sendChatMessageUsingPost,
} from "@/api/chatMessageController";
import myAxios from "@/request";
import ChatSphere from "@/components/ChatSphere.vue"; // 引入 Axios 实例

// 当前登录用户和用户列表
const loginUserStore = useLoginUserStore();
const currentUser = ref<API.UserVO | null>(null); // 当前登录用户
const userList = ref<API.UserVO[]>([]); // 用户列表
const selectedUser = ref<API.UserVO | null>(null); // 当前选中的聊天用户
const privateMessage = ref(""); // 输入的私信内容
const messages = ref<API.ChatMessage[]>([]); // 聊天记录
const messageListRef = ref<HTMLElement | null>(null); // 消息列表的引用
// 心跳间隔（毫秒）
const HEARTBEAT_INTERVAL = 30000; // 30秒
const HEARTBEAT_TIMEOUT = 10000; // 心跳超时时间，10秒
let heartbeatInterval: ReturnType<typeof setInterval> | null = null;
let heartbeatTimeout: ReturnType<typeof setTimeout> | null = null;

// WebSocket 连接
let websocket: WebSocket | null = null;

// 重连相关
let reconnectAttempts = 0;
const MAX_RECONNECT_ATTEMPTS = 5; // 最大重试次数
let reconnectTimeout: ReturnType<typeof setTimeout> | null = null;

// 加载用户列表
const loadUserList = async () => {
  try {
    const res = await listUserByPageUsingPost({});
    if (res.data.code === 0) {
      userList.value = res.data.data.records;
    } else {
      message.error("获取用户列表失败: " + res.data.message);
    }
  } catch (error) {
    message.error("加载用户列表时发生错误");
  }
};

// 生成聊天 ID
const generateChatId = (userId1: string, userId2: string): string => {
  const id1 = userId1 < userId2 ? userId1 : userId2;
  const id2 = userId1 < userId2 ? userId2 : userId1;
  return `${id1}_${id2}`; // 确保顺序
};

// 加载聊天记录
const loadChatHistory = async () => {
  if (!selectedUser.value || !currentUser.value) return;

  const chatId = generateChatId(currentUser.value.id, selectedUser.value.id); // 使用生成的 chatId
  try {
    const res = await getChatHistoryUsingGet({
      chatId: chatId,
      limit: "20",
    });

    if (res.data.code === 0) {
      messages.value = res.data.data.sort(
        (a, b) => dayjs(a.createTime).unix() - dayjs(b.createTime).unix()
      );
      console.log("Chat History:", messages.value);
      // 加载完消息后，滚动到最新消息
      await nextTick(() => {
        if (messageListRef.value) {
          messageListRef.value.scrollTop = messageListRef.value.scrollHeight;
        }
      });
    } else {
      message.error("获取聊天记录失败: " + res.data.message);
    }
  } catch (error) {
    message.error("加载聊天记录时发生错误");
  }
};

// 选择聊天用户
const selectUser = (user: API.UserVO) => {
  // 判断是否是自己
  if (user.id === currentUser.value?.id) {
    message.error("无法和自己聊天！");
    return;
  }
  selectedUser.value = user;
  loadChatHistory(); // 加载该用户的聊天记录
};

// 根据选中的用户过滤聊天记录
const filteredMessages = computed(() => {
  if (!selectedUser.value) return [];
  return messages.value.filter((msg) => {
    return (
      (msg.senderId === selectedUser.value.id &&
        msg.receiverId === currentUser.value?.id) ||
      (msg.senderId === currentUser.value?.id &&
        msg.receiverId === selectedUser.value.id)
    );
  });
});

// 获取用户头像
const getUserAvatar = (userId: string) => {
  const user = userList.value.find((user) => user.id === userId);
  return user ? user.userAvatar : ""; // 返回头像 URL 或空字符串
};

// 获取用户名
const getUserName = (userId: string) => {
  const user = userList.value.find((user) => user.id === userId);
  return user ? user.userAccount : "未知用户"; // 返回用户名或"未知用户"
};

// 发送私信
const sendPrivateMessage = async () => {
  if (
    !privateMessage.value.trim() ||
    !currentUser.value ||
    !selectedUser.value
  ) {
    message.error("无法发送空消息或未选择用户");
    return;
  }

  const chatId = generateChatId(currentUser.value.id, selectedUser.value.id); // 使用生成的 chatId
  const newMessage: API.ChatMessage = {
    id: null, // 如果您有消息 ID 的生成逻辑，可以在这里替换
    chatId: chatId,
    senderId: currentUser.value.id,
    receiverId: selectedUser.value.id,
    content: privateMessage.value,
    createTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
  };

  try {
    // 发送消息请求
    await sendChatMessageUsingPost(newMessage);
    messages.value.push(newMessage); // 立即添加消息到列表
    privateMessage.value = ""; // 清空输入框

    // 通过 WebSocket 发送消息
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      websocket.send(JSON.stringify(newMessage));
    } else {
      message.error("WebSocket 未连接，无法发送消息");
    }

    // 刷新聊天记录
    await loadChatHistory();
  } catch (error) {
    console.error("发送消息时发生错误:", error);
    message.error("发送消息时发生错误");
  }
};

// 处理接收到的消息
const handleIncomingMessage = (event: MessageEvent) => {
  // 先检查是否是心跳消息
  if (event.data === "ping") {
    return; // 忽略心跳消息
  }

  try {
    const messageData: API.ChatMessage = JSON.parse(event.data);
    if (
      (messageData.senderId === selectedUser.value?.id &&
        messageData.receiverId === currentUser.value?.id) ||
      (messageData.senderId === currentUser.value?.id &&
        messageData.receiverId === selectedUser.value.id)
    ) {
      messages.value.push(messageData);
      loadChatHistory(); // 刷新聊天记录
    }
  } catch (error) {
    console.error("接收到的消息格式错误:", event.data); // 记录原始消息
  }
};

// 获取 Axios 实例的 baseURL
const baseURL = myAxios.defaults.baseURL;
console.log(baseURL);

// 启动 WebSocket 连接
const startWebSocket = () => {
  if (!currentUser.value) {
    console.error("当前用户尚未加载，无法启动 WebSocket 连接");
    return;
  }

  // 动态构建 WebSocket URL
  const websocketURL = `${myAxios.defaults.baseURL
    .replace(/^http/, "ws")
    .replace(/^https/, "wss")
    .replace(/\/$/, "")}/api/websocket/${currentUser.value.id}`;

  websocket = new WebSocket(websocketURL);

  websocket.onopen = () => {
    message.success("WebSocket 连接成功");
    reconnectAttempts = 0; // 重置重连计数器
    if (reconnectTimeout) clearTimeout(reconnectTimeout); // 清除重连定时器

    // 每 30 秒发送心跳包
    heartbeatInterval = setInterval(sendHeartbeat, HEARTBEAT_INTERVAL);
  };

  websocket.onmessage = (event) => {
    if (heartbeatTimeout) clearTimeout(heartbeatTimeout); // 收到消息后清除心跳超时检测
    handleIncomingMessage(event);
  };

  websocket.onerror = (event) => {
    console.error("WebSocket 连接出错:", event);
    message.error("WebSocket 连接出错，请检查连接状态");
  };

  websocket.onclose = () => {
    message.warning("WebSocket 连接已断开，正在尝试重连...");
    reconnectWebSocket();
  };
};

// 加载登录用户信息
const loadLoginUser = async () => {
  try {
    const res = await getUserVoByIdUsingGet({
      id: loginUserStore.loginUser?.id,
    });
    if (res.data.code === 0) {
      currentUser.value = res.data.data;
      startWebSocket(); // 用户加载后启动 WebSocket
      await loadUserList(); // 加载用户列表
    } else {
      message.error("加载用户信息失败: " + res.data.message);
    }
  } catch (error) {
    message.error("加载用户信息时发生错误");
  }
};

// 发送心跳包
const sendHeartbeat = () => {
  if (websocket && websocket.readyState === WebSocket.OPEN) {
    websocket.send("ping");
    heartbeatTimeout = setTimeout(() => {
      message.warning("WebSocket 心跳超时，正在尝试重连...");
      reconnectWebSocket();
    }, HEARTBEAT_TIMEOUT);
  } else {
    reconnectWebSocket();
  }
};

// 重连 WebSocket
const reconnectWebSocket = () => {
  if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
    reconnectAttempts++;
    reconnectTimeout = setTimeout(() => {
      startWebSocket();
    }, 2000); // 2秒后重连
  } else {
    message.error("已达到最大重连次数，请手动刷新页面");
  }
};

// 组件挂载时加载登录用户
onMounted(() => {
  loadLoginUser();
});

// 组件卸载时清理 WebSocket 连接
onUnmounted(() => {
  if (websocket) {
    websocket.close();
  }
  if (heartbeatInterval) {
    clearInterval(heartbeatInterval);
  }
});
</script>

<style scoped>
.chat-room-container {
  display: flex;
  height: 100%;
}
.user-list {
  width: 250px;
  padding: 10px;
  border-right: 1px solid #ccc;
}

.user-list h3 {
  margin-bottom: 10px;
}
.user-list ul {
  padding: 0;
  list-style: none;
}
.user-list li {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.user-list li.active {
  background-color: #f0f0f0;
}
.chat-interface {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px;
}
.chat-header {
  display: flex;
  align-items: center;
}

.chat-username {
  margin-left: 10px;
}
.message-list {
  flex-grow: 1;
  margin: 10px 0;
  overflow-y: auto;
}
.message-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.message-right {
  justify-content: flex-end;
}
.message-left {
  justify-content: flex-start;
}
.message-content {
  max-width: 70%;
  padding: 10px;
  border-radius: 5px;
}
.message-bubble {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}
.message-time {
  color: #888;
  font-size: 12px;
}
.message-input {
  display: flex;
  align-items: center;
}
.chat-placeholder {
  margin-top: 50px;
  text-align: center;
}
</style>
