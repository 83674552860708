<template>
  <div id="userLoginPage">
    <h2 style="margin-bottom: 16px">用户登录</h2>
    <a-form
      :model="form"
      :style="{ width: '480px', margin: '0 auto' }"
      label-align="left"
      auto-label-width
      @submit="handleSubmit"
    >
      <a-form-item field="userName" tooltip="请输入账号" label="账号">
        <a-input v-model="form.userAccount" placeholder="请输入账号" />
      </a-form-item>
      <a-form-item field="userPassword" tooltip="密码不小于8位" label="密码">
        <a-input-password
          v-model="form.userPassword"
          placeholder="请输入密码"
        />
      </a-form-item>
      <a-form-item>
        <div
          style="
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
          "
        >
          <a-button type="primary" html-type="submit" style="width: 120px">
            登录
          </a-button>
          <a-link href="/user/register">新用户注册</a-link>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import API from "@/api"; // 确保正确导入API模块
import { useLoginUserStore } from "@/store/userStore"; // 确保store路径正确
import { userLoginUsingPost } from "@/api/userController"; // 确保API路径正确
import message from "@arco-design/web-vue/es/message"; // Arco Design的消息提示
import { useRouter } from "vue-router"; // 导入路由

const router = useRouter(); // 实例化路由
const loginUserStore = useLoginUserStore(); // 使用用户登录状态管理
const form = reactive({
  userAccount: "", // 账号
  userPassword: "", // 密码
} as API.UserLoginRequest); // 定义表单数据结构

/**
 * 提交登录
 */
const handleSubmit = async () => {
  try {
    const res = await userLoginUsingPost(form); // 调用登录API
    if (res.data.code === 0) {
      // 登录成功
      await loginUserStore.fetchLoginUser(); // 获取登录用户信息
      message.success("登录成功"); // 显示成功消息
      router.push({
        path: "/", // 登录成功后重定向到首页
        replace: true,
      });
    } else {
      message.error(res.data.message as string); // 显示错误消息
    }
  } catch (error) {
    message.error("登录请求失败，请稍后再试"); // 处理请求错误
  }
};
</script>

<style scoped>
#userLoginPage {
  margin-top: 50px;
  /* 文本居中 */
  text-align: center;
}
</style>
