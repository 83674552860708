// @ts-ignore
/* eslint-disable */
import request from '@/request';

/** addUserSign POST /api/userSign/add */
export async function addUserSignUsingPost(
  body: API.UserSignAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseLong_>('/api/userSign/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** deleteUserSign POST /api/userSign/delete */
export async function deleteUserSignUsingPost(
  body: API.DeleteRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/userSign/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** editUserSign POST /api/userSign/edit */
export async function editUserSignUsingPost(
  body: API.UserSignEditRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/userSign/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** getUserSignVOById GET /api/userSign/get/vo */
export async function getUserSignVoByIdUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUserSignVOByIdUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseListDateTime_>('/api/userSign/get/vo', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** listUserSignByPage POST /api/userSign/list/page */
export async function listUserSignByPageUsingPost(
  body: API.UserSignQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageUserSign_>('/api/userSign/list/page', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** listUserSignVOByPage POST /api/userSign/list/page/vo */
export async function listUserSignVoByPageUsingPost(
  body: API.UserSignQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageUserSignVO_>('/api/userSign/list/page/vo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** listMyUserSignVOByPage POST /api/userSign/my/list/page/vo */
export async function listMyUserSignVoByPageUsingPost(
  body: API.UserSignQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageUserSignVO_>('/api/userSign/my/list/page/vo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** updateUserSign POST /api/userSign/update */
export async function updateUserSignUsingPost(
  body: API.UserSignUpdateRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/userSign/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
