<template>
  <div>
    <!-- 切换按钮 -->
    <div class="toggle-button">
      <a-button type="primary" @click="toggleCalendar">
        切换到{{ showCustomCalendar ? "ECharts日历" : "自定义日历" }}
      </a-button>
    </div>

    <!-- 自定义日历组件 -->
    <customCalendar
      v-if="showCustomCalendar"
      v-model:checkedDate="checkedDate"
      v-model:startYearMonth="startYearMonth"
      :markDate="markDate"
      @clickDate="clickDate"
      @changeMonth="changeMonth"
    />

    <!-- ECharts 日历热图组件 -->
    <EchartsCalendar
      v-else
      :checkedDate="checkedDate"
      :startYearMonth="startYearMonth"
      :markDate="markDate"
      @clickDate="clickDate"
      @changeMonth="changeMonth"
    />
  </div>
</template>

<script setup lang="ts">
import API from "@/api";
import { ref, onMounted } from "vue";
import { useLoginUserStore } from "@/store/userStore";
import {
  getUserSignVoByIdUsingGet,
  addUserSignUsingPost,
} from "@/api/userSignController";
import customCalendar from "../add/components/CustomCalendar.vue"; // 自定义日历组件
import EchartsCalendar from "@/components/EchartsCalendar.vue"; // ECharts 日历热图组件
import message from "@arco-design/web-vue/es/message";

// 控制日历视图
const showCustomCalendar = ref(true);
const toggleCalendar = () => {
  showCustomCalendar.value = !showCustomCalendar.value;
};

// 日期处理
const formatToChinaTime = (dateString: string) => {
  const utcDate = new Date(dateString);
  return new Date(utcDate.getTime() + 8 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 10);
};

// 签到日期数据
const markDate = ref<string[]>([]); // 签到标记的日期
const today = formatToChinaTime(new Date().toISOString()); // 获取今天的日期
const checkedDate = ref(today); // 选中的日期
const startYearMonth = ref(today.slice(0, 7)); // 当前起始年月

const loginUserStore = useLoginUserStore();
const userId = loginUserStore.loginUser?.id;

// 签到数据
const addSignData = ref<API.UserSignAddRequest>({
  signDate: "",
  userId: userId,
});

// 点击日期签到处理
const clickDate = async (date: string) => {
  const clickedDate = new Date(date);
  const currentDate = new Date(today);

  // 仅能签到今天的日期
  if (clickedDate > currentDate || clickedDate < currentDate) {
    return;
  }

  checkedDate.value = date;
  addSignData.value.signDate = date;
  await addSignIn();
  updateCheckedStatus();
};

// 签到状态
const showCheckedStatus = ref(false);
const checkedStatus = ref("");
const updateCheckedStatus = () => {
  showCheckedStatus.value = true;
  checkedStatus.value = markDate.value.includes(checkedDate.value)
    ? "已签到"
    : "未签到";
};

// 添加签到数据
const addSignIn = async () => {
  try {
    const res = await addUserSignUsingPost(addSignData.value);
    if (res.data.code === 0) {
      message.success("签到成功");
      updateCheckedStatus();
    } else {
      message.error(res.data.message as string);
    }
  } catch (error) {
    message.error("加载签到记录失败");
  }
};

// 加载签到记录
const loadSignInData = async () => {
  try {
    const res = await getUserSignVoByIdUsingGet({
      id: loginUserStore.loginUser.id as any,
    });
    if (res.data.code === 0) {
      markDate.value = res.data.data
        .map((record: string) => formatToChinaTime(record))
        .filter(Boolean);
      updateCheckedStatus();
    } else {
      message.error(res.data.message as string);
    }
  } catch (error) {
    message.error("加载签到记录失败");
  }
};

// 监听月份切换
const changeMonth = (date: string) => {
  console.log(date);
};

// 初始化加载签到数据
onMounted(() => {
  loadSignInData();
});
</script>

<style scoped>
.m-calendar {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.toggle-button {
  margin-bottom: 10px;
  text-align: center;
}
</style>
