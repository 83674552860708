<template>
  <div class="chat-widget">
    <div id="chatwoot"></div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";

const initChatwoot = () => {
  (function (d: Document, t: string) {
    const BASE_URL = "https://app.voxsig.com";
    const g = d.createElement(t);
    const s = d.getElementsByTagName(t)[0];
    g.src = `${BASE_URL}/packs/js/sdk.js`;
    g.defer = true;
    g.async = true;
    s.parentNode?.insertBefore(g, s);
    g.onload = () => {
      window.chatwootSDK.run({
        websiteToken: "VwFyYBvwhmwzPF7hdizEXHvY",
        baseUrl: BASE_URL,
      });
    };
  })(document, "script");
};

onMounted(() => {
  initChatwoot();
});
</script>

<style scoped>
.chat-widget {
}
</style>
